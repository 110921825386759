import React from "react"

import { css } from "@emotion/core"
import { HashLoader } from "react-spinners"
import Box from "@material-ui/core/Box"
import blue from "@material-ui/core/colors/blue"
import Typography from "@material-ui/core/Typography"

class LoaderView extends React.Component<{}> {
  timerID: any

  state = {
    seconds: 0,
    interval: 300,
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), this.state.interval)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick = () => {
    this.setState((state, props) => ({
      seconds: state.seconds + this.state.interval / 1000,
    }))
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
    `
    return (
      <>
        <Box width={1} display="flex" justifyContent="center">
          <HashLoader
            css={override}
            size={128}
            color={blue[100]}
            loading={true}
          />
        </Box>

        <br />

        <Typography variant="caption" gutterBottom align="center">
          Elasped Time: {this.state.seconds.toPrecision(2)}s.
        </Typography>
      </>
    )
  }
}

export default LoaderView
