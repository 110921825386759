import React from "react"

import ErrorView, { LinkError } from "../components/error"
import PlaylistView from "../components/playlist"
import FormatsView from "../components/formats"

interface ResultViewProps {
  result: any
  error: any

  doParse(url: string): void
  clearResult(): void
}

class ResultView extends React.Component<ResultViewProps, object> {
  render() {
    if (this.props.error) {
      return (
        <ErrorView
          error={this.props.error}
          clearError={this.props.clearResult}
        />
      )
    }

    if (!this.props.result) {
      return <></>
    }

    const result = this.props.result

    if (result.error) {
      let e = new LinkError("URL is invalid or not supported.")
      e.link = {
        href: "/sites",
        text: "Supported Sites",
      }
      return <ErrorView error={e} clearError={this.props.clearResult} />
    }

    if (result._type == "playlist") {
      return (
        <PlaylistView
          result={result}
          doParse={this.props.doParse}
          clearResult={this.props.clearResult}
        />
      )
    }

    return <FormatsView result={result} clearResult={this.props.clearResult} />
  }
}

export default ResultView
