import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Img from "react-image";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const styles = theme => ({
  root: {
    color: theme.palette.common.white
  }
});

interface PlaylistViewProps {
  result: object;
  classes: any;

  doParse(url: string): void;
}

class PlaylistView extends React.Component<PlaylistViewProps, object> {
  render() {
    const result = this.props.result;
    const classes = this.props.classes;

    return (
      <Paper>
        <Table style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <CustomTableCell>
                <Typography variant="h6" gutterBottom className={classes.root}>
                  {result.title}
                </Typography>
              </CustomTableCell>
              <CustomTableCell align="right">
                <Tooltip title="The page of given URL is a playlist.">
                  <IconButton aria-label="Playlist" className={classes.root}>
                    <HelpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.entries.map((entry, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="subtitle1" gutterBottom>
                    {index + 1}. {entry.title}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Parse Item">
                    <IconButton
                      aria-label="Playlist"
                      onClick={() => {
                        this.props.doParse(entry.webpage_url);
                      }}
                    >
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="center" padding="checkbox">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  href="http://vdigger.com/?from=m3u8-downloader.com"
                  target="_blank"
                >
                  Download Playlist with Getflv
                </Button>
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Clear Result">
                  <IconButton
                    color="secondary"
                    onClick={this.props.clearResult}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(PlaylistView);
