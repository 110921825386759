import React from "react"
import Lodash from "lodash"

import { Img } from "react-image"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableFooter from "@material-ui/core/TableFooter"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"

import IconButton from "@material-ui/core/IconButton"
import GetAppIcon from "@material-ui/icons/GetApp"
import LinkIcon from "@material-ui/icons/Link"
import StarIcon from "@material-ui/icons/Star"
import Snackbar from "@material-ui/core/Snackbar"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/Delete"
import copy from "copy-to-clipboard"

interface FormatsViewProps {
  result: object
  clearResult(): void
}

class FormatsView extends React.Component<FormatsViewProps, object> {
  state = {
    open: false,
  }

  showSnackbar = () => {
    this.setState({ open: true })
  }

  closeSnackbar = () => {
    this.setState({ open: false })
  }

  render() {
    let result = this.props.result
    const bestFormatID: string = result["best-format"]
      ? result["best-format"]["format_id"]
      : result["format_id"]

    const sortedFormats = Lodash.sortBy(
      result.formats,
      "format_id",
      "desc"
    ).reverse()

    return (
      <Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.open}
          autoHideDuration={3000}
          onClose={this.closeSnackbar}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Copied to clipboard</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.closeSnackbar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" gutterBottom>
                    {result.title}
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <Img width="128" height="72" src={result.thumbnail} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedFormats.map((format: any, index: number) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell align="left">
                      {format["format_id"].toString() === bestFormatID ? (
                        <StarIcon fontSize="small" color="error" />
                      ) : null}

                      {format.format}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Download In Browser">
                        <IconButton
                          aria-label="Download"
                          href={format.url}
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <GetAppIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Copy URL">
                        <IconButton
                          aria-label="Copy to Clipboard"
                          onClick={() => {
                            copy(format.url)
                            this.showSnackbar()
                          }}
                        >
                          <LinkIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default FormatsView
