import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Link from "@material-ui/core/Link"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import ErrorIcon from "@material-ui/icons/Error"

class LinkInfo {
  href: string
  text: string
}

export class LinkError extends Error {
  link: LinkInfo
}

interface ErrorViewProps {
  classes: any
  error: LinkError

  clearError(): void
}

const styles = theme => ({
  error: {
    margin: "auto",
    backgroundColor: theme.palette.error.dark,
    maxWidth: "80%",
  },

  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
})

// |⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺⎺|
// | Error Message (Link) [X] |
// |________________⎺⎺⎺⎺______|
class ErrorView extends React.Component<ErrorViewProps> {
  handleClose = event => {
    this.props.clearError()
  }

  messageWithLink = () => {
    const { classes } = this.props

    return (
      <span id="client-snackbar" className={classes.message}>
        <ErrorIcon className={(classes.icon, classes.iconVariant)} />
        {this.props.error.message}
        {this.props.error.link ? this.messageLink() : null}
      </span>
    )
  }

  messageLink = () => {
    const { href, text } = this.props.error.link

    return (
      <div style={{ marginLeft: "16px" }}>
        <Link
          color="textSecondary"
          href={href}
          target="_blank"
          align="right"
          variant="caption"
        >
          ({text})
        </Link>
      </div>
    )
  }

  closeIcon = () => {
    const { classes } = this.props

    return (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={this.handleClose}
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <SnackbarContent
        variant="elevation"
        aria-describedby="error-snackbar"
        className={classes.error}
        message={this.messageWithLink()}
        action={[this.closeIcon()]}
      />
    )
  }
}

export default withStyles(styles)(ErrorView)
