import React from "react"
import { Cache, Logger } from "aws-amplify"

import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"

import UrlInput from "../components/input"
import EmptyView from "../components/empty"
import LoaderView from "../components/loader"
import ResultView from "../components/result"
import ErrorBoundary from "../components/boundary"

enum ViewState {
  Empty = 0,
  Loading,
  Result,
  NetworkError,
}

class IndexPage extends React.Component<{}> {
  logger = new Logger("PARSE")

  state = {
    url: "",
    viewState: ViewState.Empty,
    result: null,
    error: null,
  }

  clearResult = () => {
    this.setState({
      viewState: ViewState.Empty,
      result: null,
      error: null,
    })
  }

  getAPI() {
    if (process.env.NODE_ENV === "production") {
      return "https://api.m3u8-downloader.com/api/parse"
    }

    return location.href + "api/parse"
  }

  doParse = (url: string) => {
    this.setState(
      {
        url: url,
      },
      this.handleParse
    )
  }

  // test:state:loading
  // test:parse:item
  // test:parse:list
  handleParse = () => {
    const { url } = this.state

    if (url.startsWith("test:")) {
      return this.handleTest(url)
    }

    const cachedResult = Cache.getItem(url)
    if (cachedResult) {
      this.logger.debug("Using cached result for: ", url)

      this.setState({
        viewState: ViewState.Loading,
        result: null,
        error: null,
      })

      setTimeout(
        () =>
          this.setState({
            viewState: ViewState.Result,
            result: cachedResult,
            error: null,
          }),
        1000
      )

      return
    }

    this.setState({
      viewState: ViewState.Loading,
      result: null,
      error: null,
    })

    this.doFetch(url)
  }

  doFetch = (url: string) => {
    let api = new URL(this.getAPI())
    api.searchParams.set("url", url)

    let finalApi = url.startsWith("/test") ? url : api.toString()
    const key = "SdnHkadBwMXv9uAEP1wj2xS6gfHky3L4Y3kEC1Oh"

    fetch(finalApi, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "X-API-Key": key,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network error: ${response.statusText}`)
        }

        return response
      })
      .then(response => response.json())
      .then(data => {
        this.setState(() => ({
          result: data,
          viewState: ViewState.Result,
        }))

        let expiration = new Date()
        expiration.setHours(expiration.getHours() + 1)
        Cache.setItem(url, data, { expires: expiration.getTime() })
      })
      .catch(error => {
        this.setState((state, props) => ({
          error: error,
          viewState: ViewState.NetworkError,
        }))
      })
  }

  handleTest = (url: string) => {
    const components = url.split(":")

    if (components.length < 2) {
      this.logger.debug("Wrong test input format - test:<catagory>:<value>")
      return
    }

    if (components[1] === "state") {
      if (components[2] === "empty") {
        this.setState({
          viewState: ViewState.Empty,
          error: null,
          result: null,
        })
      } else if (components[2] === "loading") {
        this.setState({
          viewState: ViewState.Loading,
          error: null,
          result: null,
        })
      } else if (components[2] === "error") {
        this.setState({
          viewState: ViewState.NetworkError,
          error: new Error("Some test error to be shown at here"),
        })
      } else {
        this.logger.debug("Unknow state:", components[2])
      }
    } else if (components[1] === "parse") {
      this.setState(
        {
          viewState: ViewState.Result,
          error: null,
        },
        () => {
          if (components[2] === "item") {
            this.doFetch("/test/data/item.json")
          } else if (components[2] === "list") {
            this.doFetch("/test/data/list.json")
          } else {
            this.logger.debug("Unknow parse type:", components[2])
          }
        }
      )
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              Extract video/audio URLs from web page
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UrlInput
              debug={process.env.NODE_ENV === "development"}
              disabled={this.state.viewState === ViewState.Loading}
              defaultURL={this.state.url}
              onParse={this.doParse}
            />
          </Grid>

          <Grid container item direction="column" xs={12}>
            {this.state.viewState === ViewState.Empty ? (
              <EmptyView doParse={this.doParse} />
            ) : null}
            {this.state.viewState === ViewState.Loading ? <LoaderView /> : null}

            <ErrorBoundary>
              <ResultView
                result={this.state.result}
                error={this.state.error}
                doParse={this.doParse}
                clearResult={this.clearResult}
              />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default IndexPage
