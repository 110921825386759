import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

const styles = {
  root: {
    margin: "auto",
    padding: "2px 4px",
    width: "80%",
  },
}

class EmptyView extends React.Component<Props, object> {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Divider variant="middle" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              WhaleDown Android - Free web video downloader for Android
            </Typography>
            <Typography align="center" variant="subtitle1" gutterBottom>
              <a href="https://www.whaledown.com/p/android" target="_blank">
                Free Download
              </a>
            </Typography>
            
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(EmptyView)
