import React from "react"
import validator from "validator"

import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"

const styles = {
  root: {
    margin: "auto",
    padding: "2px 4px",
    display: "flex",
    width: "80%",
  },
  input: {
    borderRadius: 24,
  },
  iconButton: {
    padding: 10,
  },
}

interface UrlInputProps {
  classes: object
  defaultURL: string
  disabled: boolean
  debug: boolean

  onParse(url: string): void
}

class UrlInput extends React.Component<UrlInputProps, object> {
  state = {
    valid: false,
    url: "",
  }

  validateUrl = (event: React.ChangeEvent) => {
    const text: string = event.target.value.trim()

    this.setState({
      valid: validator.isURL(text),
      url: text,
    })
  }

  handleKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()

      const text: string = e.target.value.trim()

      if (validator.isURL(text) || this.props.debug) {
        this.doParse()
      }
    }
  }

  doParse = () => {
    this.props.onParse(this.state.url)
  }

  isError = () => {
    return !this.state.valid && this.state.url.trim().length > 0
  }

  searchIcon = () => {
    const { classes } = this.props

    return (
      <IconButton
        className={classes.iconButton}
        color="primary"
        aria-label="Search"
        autoFocus={true}
        size="small"
        disabled={
          this.props.disabled || (!this.state.valid && !this.props.debug)
        }
        onClick={this.doParse}
      >
        <SearchIcon />
      </IconButton>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <TextField
        className={classes.root}
        defaultValue={this.props.defaultURL}
        error={this.isError()}
        helperText={this.isError() ? "* Not a valid URL" : undefined}
        onChange={this.validateUrl}
        onKeyPress={this.handleKey}
        margin="dense"
        variant="outlined"
        label={
          this.props.debug
            ? "Input a web page URL here | test:<category>:<value>"
            : "Input a web page URL here"
        }
        InputProps={{
          className: classes.input,
          endAdornment: (
            <InputAdornment position="end">{this.searchIcon()}</InputAdornment>
          ),
        }}
      />
    )
  }
}

export default withStyles(styles)(UrlInput)
