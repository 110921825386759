import React from "react";

import ErrorView from "../components/error";

class ErrorBoundary extends React.Component<Props, object> {
  state = {
    hasError: false,
    error: null
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, info: object) {
    this.setState({
      hasError: true
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
